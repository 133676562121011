<template>
  <div class="mt-4">
    <!-- custom fields List card starts here -->
    <vs-button
      v-if="customFieldsList.length == 0"
      flat
      v-round
      class="only-border-btn"
      size="medium"
      @click="openPopUp"
      ><plus-icon size="1.5x" class="button-icon"></plus-icon>
      Create custom field
    </vs-button>
    <vs-button
      v-else
      flat
      v-round
      class="only-border-btn float-right mb-8"
      size="medium"
      @click="openPopUp"
    >
      Create custom field
    </vs-button>

    <!-- custom fields list card starts here -->

    <vs-row class="grid grid-cols-3 grid-flow-row">
      <vs-card class="customFieldCard py-4 px-6" v-for="(customField, indextr) in customFieldsList" :key="indextr">
        <!-- {{customField}} -->
        <div class="flex justify-end">
          <span class="customFieldId">{{ customField.customFieldId }}</span>
          <a @click="openEditGroup(customField, indextr)" class="pl-5"><img :src="edit" alt="edit"/></a>
        </div>
        <vs-row>
          <vs-card class="blueCard mb-0">
            <span class="text-white flex items-center px-2 py-0.5 mb-0">
              <img :src="datepicker" alt="date" v-if="customField.type == 'date'"/>

              <img :src="currency" alt="currency" v-if="customField.type == 'currency'"/>
              <img :src="dropdown" alt="dropdown" v-if="customField.type == 'dropdown'"/>
              <img :src="text" alt="text" v-if="customField.type == 'text'"/>
              <img :src="number" alt="number" v-if="customField.type == 'number'"/>
              <img :src="fileupload" alt="fileupload" v-if="customField.type == 'file'"/>
              <span class="uppercase text-white ml-2 mb-0 text-xs">{{customField.type}}</span>
            </span>
          </vs-card>
        </vs-row>
        <vs-row>
          <h1 class="font-medium text-xl my-4">{{ customField.label }}</h1>
        </vs-row>
        <div class="lg:ml-3 md:ml-2 ml-0">
          <vs-row class="items-center mb-3">
            <i class="material-icons"> local_atm </i>
            <p class="text-base text-black mb-0 ml-2">When sending requests</p>
          </vs-row>
          <vs-row class="lg:ml-8 md:ml-2 ml-0 mb-2">
            <vs-checkbox
              v-model="customField.editableByMerchant"
              :vs-value="true"
              :disabled="customField.reqToSendRequest"
              @change="changeCheckboxValue(indextr)"
              >Editable by merchant</vs-checkbox
            >
          </vs-row>
          <vs-row class="lg:ml-8 md:ml-2 ml-0 mb-2">
            <vs-checkbox
              v-model="customField.reqToSendRequest"
              :vs-value="true"
              @change="changeCheckboxValue(indextr)"
              >Required to send request</vs-checkbox
            >
          </vs-row>
        </div>
        <hr class="my-2" />
        <div class="lg:ml-3 md:ml-2 ml-0">
          <vs-row class="items-center mb-3">
            <i class="material-icons"> laptop_mac </i>

            <p class="text-base text-black mb-0 ml-2">On payment pages</p>
          </vs-row>
          <vs-row class="lg:ml-8 md:ml-2 ml-0 mb-2">
            <vs-checkbox
              v-model="customField.viewableByCustomer"
              :vs-value="true"
              :disabled="customField.reqToSubmitPayment"
              @change="changeCheckboxValue(indextr)"
              >Viewable by customer</vs-checkbox
            >
          </vs-row>
          <vs-row class="lg:ml-8 md:ml-2 ml-0 mb-2">
            <vs-checkbox
              v-model="customField.editableByCustomer"
              :vs-value="true"
              :disabled="customField.reqToSubmitPayment"
              @change="changeCheckboxValue(indextr)"
              >Editable by customer</vs-checkbox
            >
          </vs-row>
          <vs-row class="lg:ml-8 md:ml-2 ml-0 mb-2">
            <vs-checkbox
              v-model="customField.reqToSubmitPayment"
              :vs-value="true"
              @change="changeCheckboxValue(indextr)"
              >Required to submit payment</vs-checkbox
            >
          </vs-row>
        </div>
      </vs-card>
    </vs-row>

    <!-- custom fields list card ends here -->

    <!-- custom field create popup form starts here-->
    <vs-popup
      id="halomundo"
      class="holamundo req-template-options"
      title="New custom field"
      :active.sync="popupActivo"
      :key="randomKey"
    >
      <vs-row>
        <!-- <p class="headerParagraph mb-5">
          Any fields you create will be visible to all users and can be added to
          any payment page. You can edit or remove additional information fields
          in Settings > Payment pages > Custom fields.
        </p> -->
      </vs-row>
      <form>
        <div class="my-5">
          <vs-row class="flex flex-col">
            <label class="font-light text-base mr-4">Field Name</label>
            <vs-input
              class="w-1/2 arrayInput"
              size="large"
              v-model="label"
              name="fieldName"
          /></vs-row>
          <span class="text-danger text-sm" v-show="errors.has('fieldName')">{{
            errors.first("fieldName")
          }}</span>
        </div>

        <vs-row class="mt-10 mb-4">
          <h1 class="font-light text-base">Field Type</h1>
        </vs-row>
        <!-- radio button first row starts here -->
        <vs-row class="flex-wrap justify-start">
          <!-- text -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div
              class="radio-card mt-5"
              @click="activate('text')"
              :class="{ active: fieldTypeForCss == 'text' }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Text
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio
                    v-model="type"
                    vs-value="text"
                    vs-name="fieldType"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">
                      Enter any combination of letters & numbers and special
                      characters</span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- number -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div
              class="radio-card mt-5"
              @click="activate('number')"
              :class="{ active: fieldTypeForCss == 'number' }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Number
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio
                    v-model="type"
                    vs-value="number"
                    vs-name="fieldType"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">
                      Enter value made up of numbers only (decimals
                      allowed)</span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- currency -->
          <div class="radioButtonCard">
            <div
              class="radio-card mt-5"
              @click="activate('currency')"
              :class="{ active: fieldTypeForCss == 'currency' }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Currency
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio
                    v-model="type"
                    vs-value="currency"
                    vs-name="fieldType"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info"
                      >Enter numerical, two decimal amount i.e. dollar
                      amount</span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vs-row>

        <!-- radio button first row ends here -->

        <!-- radio button second row starts here -->

        <vs-row class="mb-10 flex-wrap justify-start">
          <!-- date -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div
              class="radio-card mt-5"
              @click="activate('date')"
              :class="{ active: fieldTypeForCss == 'date' }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Date
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio
                    v-model="type"
                    vs-value="date"
                    vs-name="fieldType"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">
                      Enter date in DD/MM/YYYY format</span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- dropdown -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div
              class="radio-card mt-5"
              @click="activate('dropdown')"
              :class="{ active: fieldTypeForCss == 'dropdown' }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Dropdown
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio
                    v-model="type"
                    vs-value="dropdown"
                    vs-name="fieldType"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">
                      Select a value from a pre-defined list of options</span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- file upload -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div
              class="radio-card mt-5"
              @click="activate('file')"
              :class="{ active: fieldTypeForCss == 'file' }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  File Upload
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio
                    v-model="type"
                    vs-value="file"
                    vs-name="fieldType"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Upload and submit a PDF file</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vs-row>
        <!-- radio button second row ends here -->

        <div v-if="type == 'dropdown'">
          <div class="mt-5 mb-8">
            <h3 class="text-xl font-normal">Configure dropdown</h3>
            <hr class="mt-3 mb-2" />
          </div>
          <vs-row>
            <vs-col vs-w="6">
              <p class="mb-4">
                Add the options you want to appear in the dropdown.
              </p>
              <!-- array form starts here -->
              <div class="configureDropdown">
                <vs-row
                  class="mb-4"
                  v-for="(m, index) in dropdownSelectFields"
                  :key="index"
                >
                  <vs-col vs-w="3" vs-xs="12" class="pr-6 w-4/5">
                    <div class="arrayInput">
                      <vs-input
                        class="w-full"
                        size="large"
                        v-validate="{ required: (type = 'dropdown') }"
                        v-model="dropdownSelectFields[index]"
                        :name="`dropdownSelectFields[${index}]`"
                      />
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    vs-xs="12"
                    class="flex items-end justify-center w-1/5"
                  >
                    <div>
                      <a class="flex" @click="deleteDropdownField(m, index)"
                        ><svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5 5.5H4.16667H17.5"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.6665 5.50002V3.83335C6.6665 3.39133 6.8421 2.9674 7.15466 2.65484C7.46722 2.34228 7.89114 2.16669 8.33317 2.16669H11.6665C12.1085 2.16669 12.5325 2.34228 12.845 2.65484C13.1576 2.9674 13.3332 3.39133 13.3332 3.83335V5.50002M15.8332 5.50002V17.1667C15.8332 17.6087 15.6576 18.0326 15.345 18.3452C15.0325 18.6578 14.6085 18.8334 14.1665 18.8334H5.83317C5.39114 18.8334 4.96722 18.6578 4.65466 18.3452C4.3421 18.0326 4.1665 17.6087 4.1665 17.1667V5.50002H15.8332Z"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.3335 9.66669V14.6667"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.6665 9.66669V14.6667"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  </vs-col>
                </vs-row>
                <vs-button
                  v-round
                  size="medium"
                  @click="addDropdownOptions()"
                  class="mt-5 only-border-btn addDropdownOptions"
                >
                  <plus-icon size="1.5x" class="button-icon"></plus-icon>
                  Add option
                </vs-button>
              </div>

              <!-- array form ends here -->
            </vs-col>
            <vs-col vs-w="6">
              <h3 class="text-lg font-normal">Preview</h3>
              <vs-card class="previewCard">
                <vs-row class="w-full flex flex-col">
                  <label for="dropdown" class="previewLabel"
                    >{{ label }}
                  </label>
                  <select
                    class="w-full"
                    name="selectOption"
                    id="dropdown"
                    style="
                      border: none;
                      background: none;
                      margin-top: 5px;
                      padding-bottom: 5px;
                      border-bottom: 1px solid #bdbdbd;
                    "
                  >
                    <option
                      v-for="(selectOption, index) in dropdownSelectFields"
                      :key="index"
                      :value="selectOption"
                    >
                      {{ selectOption }}
                    </option>
                  </select>
                </vs-row>
              </vs-card>
            </vs-col>
          </vs-row>
        </div>
      </form>

      <!-- preview area starts here-->

      <div class="mt-5" v-if="type != 'dropdown'">
        <h3 class="text-lg font-normal">Preview</h3>
        <vs-card class="previewCard">
          <!-- <div slot="header" class="mt-3">

          </div> -->

          <!-- if not dropdown -->
          <vs-row class="w-full flex flex-col">
            <label class="previewLabel">{{ label }} </label>
            <div class="flex relative">
              <span
                class="text-base flex items-end"
                v-if="type == 'currency'"
                >$</span
              >
              <input
                type="file"
                class="hidden"
                v-if="type == 'file'"
                ref="uploadImgInput"
                accept="image/png"
              />
              <vs-button
                v-if="type == 'file'"
                v-round
                size="medium"
                @click="$refs.uploadImgInput.click()"
                class="only-border-btn mt-0"
                style="width: min-content"
              >
                Upload file
              </vs-button>
              <!-- <vs-input v-else :type="fieldType" class="previewInput" :class="fieldType=='date' ? 'datePreview': '' " style="height:30px !important;width:100%;" required/>
           <Calendar-icon size="1.6x" class="button-icon cal-icon"></Calendar-icon> -->

              <vs-input
                :type="type"
                v-if="type != 'date' && type != 'file'"
                class="previewInput w-full"
                required
              />
              <date-picker
                :type="type"
                v-if="type == 'date'"
                v-model="datepickerPreview"
                placeholder="mm/dd/yyyy"
                input-class="date-picker-input w-full previewInput"
                format="MM/DD/YYYY"
                valuetype="format"
                lang="jp"
              ></date-picker>
            </div>
          </vs-row>
        </vs-card>
      </div>
      <!-- preview area ends here-->
      <div class="button-section mb-0">
        <vs-button
          v-if="editPopUp"
          size="large"
          @click="deleteCustomField()"
          v-round
          >Delete</vs-button
        >
        <vs-button
          :disabled="!validateForm || !label"
          size="large mx-8"
          @click="saveCustomField()"
          v-round
          >{{ editPopUp ? "Save" : "Create field" }}</vs-button
        >

        <a @click="onCancel" class="mid-blue underline">Cancel</a>
      </div>
    </vs-popup>
    <!-- popup ends -->

    <!-- custom fields list card ends here -->
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { EditIcon, CalendarIcon, PlusIcon } from "vue-feather-icons";
import { Validator } from "vee-validate";
import { customAlphabet } from "nanoid";
const deleteicon = require("@/assets/images/delete.png");

const edit = require("@/assets/images/edit.png");
const text = require("@/assets/images/icons/text.png");
const currency = require("@/assets/images/icons/currency.png");
const fileupload = require("@/assets/images/icons/fileupload.png");
const dropdown = require("@/assets/images/icons/dropdown.png");
const datepicker = require("@/assets/images/icons/datepicker.png");
const number = require("@/assets/images/icons/number.png");
const dictionary = {
  en: {
    attributes: {
      fieldName: "field name",
    },
  },
};
Validator.localize(dictionary);

export default {
  components: { EditIcon, CalendarIcon, PlusIcon, DatePicker },
  props: ["customFieldValue"],
  data() {
    return {
      partnerId: "",
      randomKey: 0,
      value: {},
      option: {},
      user: "",
      productCustomFieldId: "",
      customFieldsList: [],
      idForEdit: "",
      fieldTypeForCss: "",
      label: "",
      dropdownSelectFields: [],
      dropdownField: "",
      type: "",
      popupActivo: false,
      editPopUp: false,
      deleteicon: deleteicon,
      edit: edit,
      number: number,
      text: text,
      currency: currency,
      fileupload: fileupload,
      dropdown: dropdown,
      datepicker: datepicker,
      datepickerPreview: "",
    };
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.userType == "admin") {
      this.partnerId = this.user._id;
    }
    if (this.user.userType == "staff") {
      this.partnerId = this.user.partnerId;
    }
    this.customFieldsList = this.customFieldValue;

    // this.getCustomFields();
  },

  methods: {
    ...mapActions("customField", [
      "createCustomField",
      "fetchCustomFieldsByPartnerId",
    ]),
    openPopUp(){
      this.randomKey++;
      this.popupActivo = true
      this.dropdownSelectFields = []
      this.type = "text"
      this.fieldTypeForCss = "text"
      this.editPopUp = false
    },

    activate(el) {
      this.fieldTypeForCss = el;
      this.type = el;
    },

    addDropdownOptions() {
      this.value = ""
      this.dropdownSelectFields.push(this.value);
    },

    // delete
    deleteDropdownField(detail, key) {
      this.dropdownSelectFields.splice(key, 1);
      // delete this.option[key];
    },

    onCancel() {
      this.randomKey++;
      this.popupActivo = false;
      (this.productCustomFieldId = ""), (this.label = "");
      this.type = "";
      this.fieldTypeForCss = "";
      this.dropdownSelectFields = [];
      this.option = ""
    },

    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    generateRandom(length) {
      return customAlphabet("1234567890", length)();
    },

    openEditGroup(selectedCustomField, indextr) {
      this.randomKey++;
      this.popupActivo = true;
      this.editPopUp = true;
      this.productCustomFieldId = selectedCustomField.customFieldId;
      this.idForEdit = selectedCustomField._id;
      this.label = selectedCustomField.label;
      this.type = selectedCustomField.type;
      this.fieldTypeForCss = selectedCustomField.type;
      this.dropdownSelectFields = selectedCustomField.dropdownSelectFields;
      this.option = selectedCustomField.dropdownSelectFields;
    },

    // change checkbox
    changeCheckboxValue(key) {
      if (this.customFieldsList[key].reqToSendRequest) {
        this.customFieldsList[key].editableByMerchant = true;
      }

      if (this.customFieldsList[key].reqToSubmitPayment) {
        this.customFieldsList[key].editableByCustomer = true;
        this.customFieldsList[key].viewableByCustomer = true;
      }

      this.$emit("savedCustomField", this.customFieldsList);
    },
    deleteCustomField() {
      let objIndex = this.customFieldsList.findIndex(
        (obj) => obj._id == this.idForEdit
      );
      this.customFieldsList.splice(objIndex, 1);
      this.randomKey++;
      this.popupActivo = false;
      this.editPopUp = false;
      this.label = "";
      this.productCustomFieldId = "";
      this.type = "text";
      this.fieldTypeForCss = "text";
      this.dropdownSelectFields = [];
      this.$emit("savedCustomField", this.customFieldsList);
    },

    saveCustomField() {
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      let fieldId = `CF-${firstBits}-${secondBits}`;
      try {
        const newField = {
          label: this.label,
          type: this.type,
          partnerId: this.partnerId,
          editableByMerchant: false,
          reqToSendRequest: false,
          viewableByCustomer: false,
          editableByCustomer: false,
          reqToSubmitPayment: false,
        };
        if (this.type == "dropdown") {
          newField.dropdownSelectFields = Object.entries(this.option).map(
            (el) => el[1]
          );
        }

        if (this.editPopUp == false) {
          newField._id = fieldId;
          newField.customFieldId = fieldId;
          this.customFieldsList.push(newField);
        }

        if (this.editPopUp == true) {
          newField._id = this.idForEdit;
          newField.customFieldId = this.productCustomFieldId;

          let objIndex = this.customFieldsList.findIndex(
            (obj) => obj._id == this.idForEdit
          );
          this.customFieldsList[objIndex].label = this.label;
          this.customFieldsList[objIndex].type = this.type;

          if (this.customFieldsList[objIndex].type == "dropdown") {
            this.customFieldsList[objIndex].dropdownSelectFields =
              Object.entries(this.option).map((el) => el[1]);
          }
        }

        this.$emit("savedCustomField", this.customFieldsList);
        this.randomKey++;
        this.popupActivo = false;
        this.label = "";
        this.type = "text";
        this.fieldTypeForCss = "text";
        this.dropdownSelectFields = [];
        this.showMessage(
          "Successful",
          "Custom field has been created successfully.",
          "success",
          "icon-check-circle"
        );
      } catch (err) {

      }
    }
  },
};
</script>
